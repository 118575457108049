import "./Footer.css";

function Footer() {
  return (
    <footer>
      <div className="footer">
        <div className="row">
          SWAPPI Copyright © 2022 Swappi - All rights reserved || Designed By:
          Niv&Lidan
        </div>
      </div>
    </footer>
  );
}

export default Footer;
